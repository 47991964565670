@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.logo {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: $logoColor;

  img {
    max-height: rem(66px);
    margin-right: 0.5rem;
  }
}
