// Fonts
$baseFont: "Roboto", "Helvetica", "Arial", sans-serif;

// Colors
$logoColor: #2f5679;

// Material UI colors

$materialUiPalette: (
        common: (
                black: #000,
                white: #fff,
        ),
        primary: (
                light: #7986cb,
                main: #3f51b5,
                dark: #303f9f,
                contrastText: #fff,
        ),
        secondary: (
                light: #ff4081,
                main: #f50057,
                dark: #c51162,
                contrastText: #fff,
        ),
        error: (
                light: #e57373,
                main: #f44336,
                dark: #d32f2f,
                contrastText: #fff,
        ),
        warning: (
                light: #ffb74d,
                main: #ff9800,
                dark: #f57c00,
                contrastText: rgba(0, 0, 0, 0.87),
        ),
        info: (
                light: #64b5f6,
                main: #2196f3,
                dark: #1976d2,
                contrastText: #fff,
        ),
        success: (
                light: #7986cb,
                main: #3f51b5,
                dark: #303f9f,
                contrastText: rgba(0, 0, 0, 0.87),
        ),
        grey: (
                50: #fafafa,
                100: #f5f5f5,
                200: #eeeeee,
                300: #e0e0e0,
                400: #bdbdbd,
                500: #9e9e9e,
                600: #757575,
                700: #616161,
                800: #424242,
                900: #212121
        ),
        text: (
                primary: rgba(0, 0, 0, 0.87),
                secondary: rgba(0, 0, 0, 0.54),
                disabled: rgba(0, 0, 0, 0.38),
                hint: rgba(0, 0, 0, 0.38),
        )
)
