@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.tox-tinymce.tox-tinymce {
  @include radius(4px);
}

.tox.tox {
  .tox-edit-area__iframe,
  .tox-toolbar-overlord,
  .tox-statusbar,
  .tox-toolbar,
  .tox-toolbar__overflow,
  .tox-toolbar__primary {
    background: transparent;
  }

  .tox-statusbar__branding,
  .tox-menubar {
    display: none;
  }

  .tox-toolbar-overlord {
    border-bottom: 1px solid #ccc;
  }

  .tox-edit-area {
    background: rgba(255, 255, 255, 0.5);
  }
}
