@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";
@import "src/assets/styles/functions";

.bodyFieldError + .newTicketBodyEditor {
  border: 1px solid map-deep-get($materialUiPalette, 'error', 'main');;
  border-radius: 4px;
}

.bodyFieldErrorMsg {
  color: map-deep-get($materialUiPalette, 'error', 'main');;
  font-size: 0.75rem;
  letter-spacing: 0.03333em;
  margin-left: 14px;
}
